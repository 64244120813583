import React, { FC, useRef } from "react";
import ReactHlsPlayer from "react-hls-player";

const url =
  "http://193.111.244.43:8080/match_tv/timeshift_abs-1625478171.m3u8?token=8b413aa9e6df4611fb55e1cf25d96760e8fa4f8a-t:1625589589";

const VideoPlayer: FC = () => {
  const playerRef = useRef<HTMLVideoElement>(null);

  return (
    <ReactHlsPlayer
      playerRef={playerRef}
      src={url}
      width="100%"
      height="100%"
      autoPlay={true}
      controls={true}
    />
  );
};
export default VideoPlayer;
