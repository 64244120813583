import React, { FC } from "react";

import { Player } from "../../../Icons";

import { IProgramsProps } from "../../types";
import { TTVChannelData } from "../../../../store/channels/types";

import "./programs.scss";
import { useSelector } from "react-redux";
import {
  makeSelectTVChannelData,
  makeSelectTVChannelLoading,
} from "../../../../store/channels/selectors";
import { convertTVChannelDataToChannelData } from "../../../../utils/convertHelpers";
import moment from "moment";
import Preloader from "../../../Preloader";

const Programs: FC<IProgramsProps> = ({ divRef }) => {
  // @ts-ignore
  const TVChannel: TTVChannelData = useSelector(makeSelectTVChannelData)?.toJS();
  const TVChannelLoading = useSelector(makeSelectTVChannelLoading);

  const channelData = convertTVChannelDataToChannelData(TVChannel);

  const programsList = channelData?.programDaysList[0].programsList || [];

  return (
    <div ref={divRef} className="program">
      <div className="program-list list-fon">
        <div className="program-list-body">
          <h2 className="program-list-text"> Программа на сегодня </h2>
        </div>
        {TVChannelLoading ? (
          <Preloader />
        ) : (
          <div className="program-list-wrapper">
            <ul className="program-list-inner ">
              {programsList.map(({ id, start, title }) => {
                return (
                  <li key={id}>
                    <h4>{moment(start).format("HH:mm")}</h4> <Player /> <abbr>{title}</abbr>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default Programs;
