import { createSelector } from "reselect";

import { IApplicationState } from "../rootInterface";
import { TChannelsStateProps, TSelectChannelsApplication } from "./types";

const selectState = (state: IApplicationState): TSelectChannelsApplication => state.channels;

export const makeSelectAllTVChannelsData = createSelector(
  selectState,
  (state: TChannelsStateProps) => {
    return state?.getIn(["allTVChannels", "data", "data"]);
  },
);

export const makeSelectAllTVChannelsLoading = createSelector(
  selectState,
  (state: TChannelsStateProps) => {
    return state?.getIn(["allTVChannels", "fetching"]);
  },
);

export const makeSelectTVChannelData = createSelector(selectState, (state: TChannelsStateProps) => {
  return state?.getIn(["TVChannel", "data", "data"]);
});

export const makeSelectTVChannelLoading = createSelector(
  selectState,
  (state: TChannelsStateProps) => {
    return state?.getIn(["TVChannel", "fetching"]);
  },
);
