import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import Menu from "./components/Menu";
import Categories from "./components/Categories";
import Channels from "./components/Channels";
import Programs from "./components/Programs";
import Date from "./components/Date";
import Weather from "./components/Weather";

import { EBlock, EKeyCode, EMenuIds, IMainMenuProps, TBlockRef, TObject } from "./types";

import "./styles.scss";
import { mockMenuList } from "./const";
import {
  makeSelectAllTVChannelsData,
  makeSelectTVChannelData,
} from "../../store/channels/selectors";
import { TAllTVChannelsData } from "../../store/channels/types";
import {
  convertChannelsToChannelsList,
  convertTVChannelDataToChannelData,
} from "../../utils/convertHelpers";
import { TChannelItem } from "./components/Channels/types";

const MainMenu: FC<IMainMenuProps> = ({}) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const categoriesRef = useRef<HTMLDivElement>(null);
  const channelsRef = useRef<HTMLDivElement>(null);
  const programsRef = useRef<HTMLDivElement>(null);
  const dateRef = useRef<HTMLDivElement>(null);

  const immutableAllTVChannels = useSelector(makeSelectAllTVChannelsData);
  const immutableTVChannel = useSelector(makeSelectTVChannelData);

  const [selectedLi, setSelectedLi] = useState<HTMLLIElement | null>(null);
  const [currentBlock, setCurrentBlock] = useState<keyof typeof EBlock>("menu");
  const [selectedListIndices, setSelectedListIndices] = useState<TObject>({
    [EBlock.menu]: 0,
    [EBlock.categories]: 0,
    [EBlock.channels]: 0,
    [EBlock.programs]: 0,
    [EBlock.date]: 0,
  });
  const [block, setBlock] = useState<TObject>({
    [EBlock.menu]: mockMenuList,
    [EBlock.categories]: [],
    [EBlock.channels]: [],
    [EBlock.programs]: [],
    [EBlock.date]: [],
  });

  const isTVMenu = block[EBlock.menu][selectedListIndices[EBlock.menu]].id === EMenuIds.TVId;

  const incrementIndex = () => {
    const idx = selectedListIndices[currentBlock];
    setSelectedListIndices({ ...selectedListIndices, [currentBlock]: idx + 1 });
  };

  const decrementIndex = () => {
    const idx = selectedListIndices[currentBlock];
    setSelectedListIndices({ ...selectedListIndices, [currentBlock]: idx - 1 });
  };

  const switchItem = useCallback(
    (sibling: HTMLLIElement) => {
      if (selectedLi) {
        selectedLi.classList.remove("active");
        sibling.classList.add("active");
        setSelectedLi(sibling);
      }
    },
    [setSelectedLi, selectedLi],
  );

  const switchBlock = (blockRef: TBlockRef, blockType: keyof typeof EBlock) => {
    if (selectedLi) {
      selectedLi.classList.add("selected");
      selectedLi.classList.remove("active");
      selectBlockElem(blockRef, blockType);
    }
  };

  const selectBlockElem = (blockRef: TBlockRef, blockType: keyof typeof EBlock) => {
    if (blockRef) {
      setCurrentBlock(blockType);
      const selectedLi: HTMLLIElement = blockRef!.current!.querySelector("li.selected")!;
      selectedLi.classList.add("active");
      selectedLi.classList.remove("selected");
      setSelectedLi(selectedLi);
    }
  };

  const selectBlockItem = (blockRef: TBlockRef, blockType: keyof typeof EBlock) => {
    const liElemsList = blockRef.current?.querySelectorAll("li");
    if (liElemsList && liElemsList.length) {
      liElemsList[selectedListIndices[blockType]]?.classList.add("selected");
    }
  };

  const selectChannelItem = (idx: number) => {
    channelsRef.current!.querySelector("li.selected")?.classList.remove("selected");

    const liElemsList = channelsRef.current?.querySelectorAll("li");

    if (liElemsList && liElemsList.length) {
      liElemsList[idx]?.classList.add("selected");
    }

    setSelectedListIndices({ ...selectedListIndices, [EBlock.channels]: idx });
  };

  const selectTVBlockItemsRespectively = () => {
    selectBlockItem(categoriesRef, EBlock.categories);
    selectBlockItem(channelsRef, EBlock.channels);
    selectBlockItem(programsRef, EBlock.programs);
    selectBlockItem(dateRef, EBlock.date);
  };

  const handleChangeCategoriesItem = () => {
    if (block[EBlock.categories].length) {
      const { id: categoryId } = block[EBlock.categories][selectedListIndices[EBlock.categories]];
      const channelsList: TChannelItem[] = block[EBlock.channels];
      const idx = channelsList.findIndex(({ genreId }) => genreId === categoryId);
      selectChannelItem(idx);
    }
  };

  const getNextLiSibling = () => {
    if (selectedLi) {
      const nextSibling = selectedLi.nextElementSibling;
      if (nextSibling) {
        if (nextSibling.tagName === "LI") {
          return nextSibling;
        } else {
          return nextSibling.nextElementSibling;
        }
      }
    }
  };

  const getPrevLiSibling = () => {
    if (selectedLi) {
      const prevSibling = selectedLi.previousElementSibling;
      if (prevSibling) {
        if (prevSibling.tagName === "LI") {
          return prevSibling;
        } else {
          return prevSibling.previousElementSibling;
        }
      }
    }
  };

  const handleClickUp = () => {
    const prevSibling = getPrevLiSibling() as HTMLLIElement;
    if (prevSibling) {
      decrementIndex();
      switchItem(prevSibling);
    }
  };

  const handleClickDown = () => {
    const nextSibling = getNextLiSibling() as HTMLLIElement;

    if (nextSibling) {
      incrementIndex();
      switchItem(nextSibling);
    }
  };

  const handleClickRight = () => {
    switch (currentBlock) {
      case EBlock.menu:
        switchBlock(categoriesRef, "categories");
        break;
      case EBlock.categories:
        switchBlock(channelsRef, "channels");
        break;
      case EBlock.channels:
        switchBlock(programsRef, "programs");
        break;
      case EBlock.programs:
        switchBlock(dateRef, "date");
        break;
    }
  };

  const handleClickLeft = () => {
    switch (currentBlock) {
      case EBlock.categories:
        switchBlock(menuRef, "menu");
        break;
      case EBlock.channels:
        switchBlock(categoriesRef, "categories");
        break;
      case EBlock.programs:
        switchBlock(channelsRef, "channels");
        break;
      case EBlock.date:
        switchBlock(programsRef, "programs");
        break;
    }
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    switch (e.code) {
      case EKeyCode.arrowUp:
        handleClickUp();
        break;
      case EKeyCode.arrowDown:
        handleClickDown();
        break;
      case EKeyCode.arrowRight:
        handleClickRight();
        break;
      case EKeyCode.arrowLeft:
        handleClickLeft();
        break;
    }
  };

  useEffect(() => {
    selectBlockItem(menuRef, EBlock.menu);
    selectBlockElem(menuRef, EBlock.menu);
  }, [menuRef]);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  });

  useEffect(() => {
    console.log(block[currentBlock][selectedListIndices[currentBlock]]);
  }, [selectedListIndices, currentBlock]);

  useEffect(() => {
    // @ts-ignore
    const allTVChannels: TAllTVChannelsData = immutableAllTVChannels?.toJS();
    // @ts-ignore
    const TVChannel = immutableTVChannel?.toJS();

    const genres = allTVChannels?.genres || [];
    const channelsList = convertChannelsToChannelsList(allTVChannels?.channels);

    const channelData = convertTVChannelDataToChannelData(TVChannel);
    const programDaysList = channelData?.programDaysList || [];
    const programsList = programDaysList.length ? programDaysList[0].programsList : [];

    setBlock({
      ...block,
      [EBlock.categories]: genres,
      [EBlock.channels]: channelsList,
      [EBlock.programs]: programsList,
      [EBlock.date]: programDaysList,
    });

    selectTVBlockItemsRespectively();
  }, [immutableAllTVChannels, immutableTVChannel]);

  useEffect(() => {
    if (categoriesRef.current) {
      selectTVBlockItemsRespectively();
    }
  }, [categoriesRef.current]);

  useEffect(() => {
    handleChangeCategoriesItem();
  }, [selectedListIndices[EBlock.categories]]);

  return (
    <div className="menu-wrapper">
      <div className="container">
        <Menu divRef={menuRef} />
        {isTVMenu && (
          <>
            <Categories divRef={categoriesRef} />
            <Channels divRef={channelsRef} />
            <Programs divRef={programsRef} />
            <Date divRef={dateRef} />
          </>
        )}
        {block[EBlock.menu][selectedListIndices[EBlock.menu]].id === EMenuIds.weatherId && (
          <Weather />
        )}
      </div>
    </div>
  );
};

export default MainMenu;
