type TActionReturnType<T> = {
  type: string;
  payload?: T;
};

type TUtilType<T extends object | boolean = never> = (payload?: T) => TActionReturnType<T>;

export default <T extends object | boolean = never>(actionType: string): TUtilType<T> =>
  (payload) => ({
    type: actionType,
    payload,
  });
