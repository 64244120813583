import React, { FC } from "react";

import "./menu.scss";

import { mockMenuList } from "../../const";
import { IMenuProps } from "../../types";

const Menu: FC<IMenuProps> = ({divRef}) => {
  return (
    <div ref={divRef} className="menu-wrapper-inner list-fon">
      <ul className="scrollable-list">
        {mockMenuList.map(({ id, icon, title }) => {
          return (
            <li className="scrollable-list-item" key={id}>
              <em>
                {icon}
                <abbr>{title}</abbr>
              </em>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Menu;
