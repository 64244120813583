import React, { FC } from "react";

import { IChannelsProps } from "../../types";

import "./styles.scss";
import { TAllTVChannelsData } from "../../../../store/channels/types";
import { useSelector } from "react-redux";
import {
  makeSelectAllTVChannelsData,
  makeSelectAllTVChannelsLoading,
} from "../../../../store/channels/selectors";
import { convertChannelsToChannelsList } from "../../../../utils/convertHelpers";
import Preloader from "../../../Preloader";

const Channels: FC<IChannelsProps> = ({ divRef }) => {
  // @ts-ignore
  const allTVChannels: TAllTVChannelsData = useSelector(makeSelectAllTVChannelsData)?.toJS();
  const allTVChannelsLoading = useSelector(makeSelectAllTVChannelsLoading);
  const channelsList = convertChannelsToChannelsList(allTVChannels?.channels);

  return (
    <div ref={divRef} className="channel">
      <div className="channel-list list-fon">
        <div className="channel-list-body">
          <h2 className="channel-list-text"> Каналы </h2>
        </div>
        {allTVChannelsLoading ? (
          <Preloader />
        ) : (
          <div className="channel-list-wrapper">
            <ul className="channel-list-inner ">
              {channelsList.map(({ isHeader, id, title, genre }, index) => {
                return (
                  <>
                    {isHeader && <h3> {genre} </h3>}

                    <li key={id}>
                      <h4>{index + 1}</h4> <abbr>{title}</abbr>
                    </li>
                  </>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default Channels;
