
export enum EMenuIds {
  TVId = "1",
  weatherId = "2",
  radioId = "3",
  settingId = "4",
  complainId = "5",
}

export type TMenuItem = {
  id: EMenuIds;
  icon: JSX.Element;
  title: string;
};

export type TBaseItem = {
  title: string;
};

export type TBlockRef = React.RefObject<HTMLDivElement>;

export interface IMenuComponent {
  divRef: TBlockRef;
}

export interface IMainMenuProps {}

export interface IMenuProps extends IMenuComponent {}

export interface ICategeriesProps extends IMenuComponent {}

export interface IChannelsProps extends IMenuComponent {}

export interface IProgramsProps extends IMenuComponent {}

export interface IDateProps extends IMenuComponent {}

export enum EKeyCode {
  arrowUp = "ArrowUp",
  arrowDown = "ArrowDown",
  arrowLeft = "ArrowLeft",
  arrowRight = "ArrowRight",
}

export enum EBlock {
  menu = "menu",
  categories = "categories",
  channels = "channels",
  programs = "programs",
  date = "date",
}

export type TObject = {
  [key: string]: any;
};
