import React, { FC, useState, useEffect } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useDispatch, useSelector } from "react-redux";

import Menu from "components/Menu";
import VideoPlayer from "./components/VideoPlayer";
import { TAllTVChannelsData } from "./store/channels/types";
import { makeSelectAllTVChannelsData } from "./store/channels/selectors";
import { getAllTVChannelsRequest, getTVChannelRequest } from "./store/channels/actions";

import "./App.css";

const App: FC = () => {
  const dispatch = useDispatch();
  const immutableAllTVChannels = useSelector(makeSelectAllTVChannelsData);
  // @ts-ignore
  const allTVChannels: TAllTVChannelsData = immutableAllTVChannels?.toJS();

  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenuOpen = () => {
    setMenuOpen(!isMenuOpen);
  };

  useHotkeys("h", toggleMenuOpen, [isMenuOpen]);

  useEffect(() => {
    dispatch(getAllTVChannelsRequest());
  }, []);

  useEffect(() => {
    const firstChannelId = allTVChannels?.channels[0].id;
    if (firstChannelId) {
      dispatch(getTVChannelRequest({ item_id: firstChannelId }));
    }
  }, [immutableAllTVChannels]);

  return (
    <div className="container">
      <VideoPlayer />
      {isMenuOpen && <Menu />}
    </div>
  );
};
export default App;
