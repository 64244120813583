import React, { FC } from "react";

import { CloudIcon } from "../../../Icons";

import "./weather.scss";

const Weather: FC = () => {
  return (
    <div className="weather">
      <div className="weather-list list-fon">
        <div className="weather-list-body">
          <h2 className="weather-list-text"> Каналы </h2>
        </div>
        <div className="weather-list-wrapper">
          <ul className="weather-list-inner ">
            <span>
              {" "}
              <h2>Сейчас</h2>{" "}
              <h3>
                {" "}
                <h6>
                  <CloudIcon />{" "}
                </h6>{" "}
                +31
              </h3>{" "}
            </span>
            <h4> Позже </h4>
            <li>
              <h2> Вт, завтра</h2>
              <h3>
                <h6>
                  <CloudIcon />
                </h6>{" "}
                +31
              </h3>
              <h5> +23 </h5>
            </li>
            <li>
              <h2> Вт, завтра</h2>
              <h3>
                <h6>
                  <CloudIcon />
                </h6>{" "}
                +31
              </h3>
              <h5> +23 </h5>
            </li>
            <li>
              <h2> Вт, завтра</h2>
              <h3>
                <h6>
                  <CloudIcon />
                </h6>{" "}
                +31
              </h3>
              <h5> +23 </h5>
            </li>
            <li>
              <h2> Вт, завтра</h2>
              <h3>
                <h6>
                  <CloudIcon />
                </h6>{" "}
                +31
              </h3>
              <h5> +23 </h5>
            </li>
            <li>
              <h2> Вт, завтра</h2>
              <h3>
                <h6>
                  <CloudIcon />
                </h6>{" "}
                +31
              </h3>
              <h5> +23 </h5>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Weather;
