import React, { FC } from "react";
import { useSelector } from "react-redux";

import { ICategeriesProps } from "../../types";
import {
  makeSelectAllTVChannelsData,
  makeSelectAllTVChannelsLoading,
} from "../../../../store/channels/selectors";
import { TAllTVChannelsData } from "../../../../store/channels/types";
import Preloader from "../../../Preloader";

import "./styles.scss";

const Categories: FC<ICategeriesProps> = ({ divRef }) => {
  // @ts-ignore
  const allTVChannels: TAllTVChannelsData = useSelector(makeSelectAllTVChannelsData)?.toJS();
  const genres = allTVChannels?.genres || [];
  const allTVChannelsLoading = useSelector(makeSelectAllTVChannelsLoading);

  return (
    <div ref={divRef} className="category">
      <div className="category-list list-fon">
        <div className="category-list-body">
          <h2 className="category-list-text"> Категории </h2>
        </div>
        {allTVChannelsLoading ? (
          <Preloader />
        ) : (
          <div className="category-list-wrapper">
            <ul className="category-list-inner ">
              {genres.map(({ id, name }) => {
                return (
                  <li key={id}>
                    <abbr>{name}</abbr>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default Categories;
