import { combineReducers } from "redux";
import channels from "./channels/reducer";
import { IApplicationState } from "./rootInterface";

const rootReducer = combineReducers<IApplicationState>({
  channels,
});

export type TRootState = ReturnType<typeof rootReducer>;

export default rootReducer;
