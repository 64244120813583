import React, { FC } from "react";
import { IIcon } from "./types";

const MusicIcon: FC<IIcon> = ({ color = "white", width = 34, height = 30 }) => {
  return (

    <svg xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 512 512"
         width={width}
         height={height}
    >
      <path
        fill={color}
        d="M480.06 0a31.94 31.94 0 0 0-9.68 1.5l-304 96A32 32 0 0 0 144 128v235.09A109.68 109.68 0 0 0 96 352c-53 0-96 35.81-96 80s43 80 96 80c49.38 0 89.56-31.16 94.91-71.09a38.74 38.74 0 0 0 1.06-8.66V256l272-85.91v129A109.78 109.78 0 0 0 416 288c-53 0-96 35.81-96 80s43 80 96 80c49.38 0 89.56-31.19 94.94-71.12a38.94 38.94 0 0 0 1-8.22c0-.22.06-.44.06-.66V32a32 32 0 0 0-31.94-32zM96 464c-28.28 0-48-16.88-48-32s19.72-32 48-32 48 16.84 48 32-19.72 32-48 32zm368-96c0 15.12-19.72 32-48 32s-48-16.88-48-32 19.72-32 48-32 48 16.84 48 32zm0-248.25l-272 85.91v-65.91l272-85.87z" />
    </svg>
  );
};

export default MusicIcon;
