import { all, takeLatest, call, put } from "redux-saga/effects";

import request from "../../API";
import {
  GET_ALL_TV_CHANNELS_REQUEST,
  GET_TV_CHANNEL_REQUEST,
  getAllTVChannelsFailure,
  getAllTVChannelsSuccess,
  getTVChannelFailure,
  getTVChannelSuccess,
} from "./actions";
import { AxiosResponse } from "axios";
import { TGetAllTVChannelsSuccess, TGetTVChannelRequest, TGetTVChannelSuccess } from "./types";
import { IPayloadAction } from "../rootInterface";

function* getAllTVChannels() {
  try {
    const params = { serial_number: "9cd9a6ac-02c6-446c-09aa-a551ab82f7d7" };

    const response: AxiosResponse<TGetAllTVChannelsSuccess> = yield call(
      request.get,
      "deviceAPI/getAllTVChannels",
      { params },
    );

    yield put(getAllTVChannelsSuccess(response.data));
  } catch (error) {
    yield put(getAllTVChannelsFailure(error));
  }
}

function* getTVChannel(action: IPayloadAction<TGetTVChannelRequest>) {
  try {
    const { item_id } = action.payload;
    const params = { item_id, serial_number: "9cd9a6ac-02c6-446c-09aa-a551ab82f7d7" };

    const response: AxiosResponse<TGetTVChannelSuccess> = yield call(
      request.get,
      "deviceAPI/GetTVChannel",
      { params },
    );

    yield put(getTVChannelSuccess(response.data));
  } catch (error) {
    yield put(getTVChannelFailure(error));
  }
}

function* Saga() {
  yield all([
    takeLatest(GET_ALL_TV_CHANNELS_REQUEST, getAllTVChannels),
    takeLatest(GET_TV_CHANNEL_REQUEST, getTVChannel),
  ]);
}

export default Saga;
