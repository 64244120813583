import moment from "moment";
import "moment/locale/ru";

import { TChannel, TServerProgram, TTVChannelData } from "../store/channels/types";
import { TChannelItem } from "../components/Menu/components/Channels/types";
import {
  EProgramStatus,
  TChannelData,
  TProgramDayItem,
  TProgramItem,
} from "../components/Menu/components/Programs/types";

export const convertChannelsToChannelsList = (channels: TChannel[] = []): TChannelItem[] => {
  const channelsList: TChannelItem[] = [];
  const channelsLength = channels.length;

  const convertChannelToChannelItem = (
    { genres, id, title, channelIcon, genre, channel_parental_protect }: TChannel,
    isHeader = false,
  ): TChannelItem => {
    const [genreId] = genres;
    return {
      id,
      title,
      imageUrl: channelIcon,
      channel_parental_protect,
      isHeader,
      genreId,
      genre,
    };
  };

  if (channelsLength) {
    channelsList.push(convertChannelToChannelItem(channels[0], true));
    for (let i = 1; i < channelsLength; i++) {
      const [prevChannelGenreId] = channels[i - 1].genres;
      const [currentChannelGenreId] = channels[i].genres;
      const isHeader = prevChannelGenreId !== currentChannelGenreId;
      channelsList.push(convertChannelToChannelItem(channels[i], isHeader));
    }
  }

  return channelsList;
};

export const convertDateToTitleDate = (timestamp: number): string => {
  return moment(timestamp).format("D MMMM, ddd");
};

export const convertServerProgramToProgramItem = (program: TServerProgram): TProgramItem => {
  return {
    title: program.name,
    id: program.programm_id,
    start: convertToMs(program.start),
    stop: convertToMs(program.stop),
    duration: program.stop - program.start,
    status: getProgramStatus(convertToMs(program.start), convertToMs(program.stop)),
  };
};

export const getProgramStatus = (start: number, stop: number): EProgramStatus => {
  const now = +moment();
  if (stop < now) return EProgramStatus.finished;
  else if (start > now) return EProgramStatus.notStarted;
  else return EProgramStatus.online;
};

const convertProgramToProgramDayItem = (program: TServerProgram): TProgramDayItem => ({
  day: convertDateToTitleDate(convertToMs(program.start)),
  timestamp: convertToMs(program.start),
  programsList: [convertServerProgramToProgramItem(program)],
});

const convertProgramsToProgramDaysList = (programs: TServerProgram[]): TProgramDayItem[] => {
  const programDaysList: TProgramDayItem[] = [];
  const programsLength = programs.length;

  programDaysList.push(convertProgramToProgramDayItem(programs[0]));

  for (let i = 1; i < programsLength; i++) {
    const prevTitleDate = convertDateToTitleDate(convertToMs(programs[i - 1].start));
    const currentTitleDate = convertDateToTitleDate(convertToMs(programs[i].start));

    if (prevTitleDate === currentTitleDate) {
      const lastIdx = programDaysList.length - 1;
      programDaysList[lastIdx].programsList.push(convertServerProgramToProgramItem(programs[i]));
    } else {
      programDaysList.push(convertProgramToProgramDayItem(programs[i]));
    }
  }

  return programDaysList;
};

export const convertTVChannelDataToChannelData = (
  channelDetail: TTVChannelData,
): TChannelData | undefined => {
  if (!channelDetail) return;

  return {
    id: channelDetail.id,
    title: channelDetail.title,
    channelIcon: channelDetail.channelIcon,
    programDaysList: convertProgramsToProgramDaysList(channelDetail.program),
  };
};

export const convertToMs = (seconds: number) => seconds * 1000;
