export enum EProgramStatus {
  finished = "finished",
  online = "online",
  notStarted = "notStarted",
}

export type TProgramItem = {
  title: string;
  id: number;
  start: number;
  stop: number;
  duration: number;
  status: EProgramStatus;
};

export type TProgramDayItem = {
  day: string;
  timestamp: number;
  programsList: TProgramItem[];
};

export type TChannelData = {
  id: number;
  channelIcon: string;
  title: string;
  programDaysList: TProgramDayItem[];
};
