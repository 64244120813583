import { fromJS } from "immutable";

import createReducer from "../utils/createReducer";

import {
  TChannelsRecordOf,
  TChannelsType,
  TGetAllTVChannelsError,
  TGetAllTVChannelsSuccess,
  TGetTVChannelError,
  TGetTVChannelSuccess,
  TSetChannelsDataLoading,
} from "./types";
import { initialDataState } from "../consts";
import {
  GET_ALL_TV_CHANNELS_FAILURE,
  GET_ALL_TV_CHANNELS_REQUEST,
  GET_ALL_TV_CHANNELS_SUCCESS,
  GET_TV_CHANNEL_FAILURE,
  GET_TV_CHANNEL_REQUEST,
  GET_TV_CHANNEL_SUCCESS,
} from "./actions";

// @ts-ignore
const initialState: TChannelsRecordOf = fromJS({
  allTVChannels: initialDataState,
  TVChannel: initialDataState,
});

const setAllTVChannelsLoading: TSetChannelsDataLoading = (value) => (state) => {
  return state.setIn(["allTVChannels", "fetching"], value);
};
const setAllTVChannelsSuccess: TChannelsType<TGetAllTVChannelsSuccess> = (state, action) => {
  return state.setIn(["allTVChannels", "data"], fromJS(action.payload));
};
const setAllTVChannelsFailure: TChannelsType<TGetAllTVChannelsError> = (state, action) => {
  return state.setIn(["allTVChannels", "error"], fromJS(action.payload));
};

const setTVChannelLoading: TSetChannelsDataLoading = (value) => (state) => {
  return state.setIn(["TVChannel", "fetching"], value);
};
const setTVChannelSuccess: TChannelsType<TGetTVChannelSuccess> = (state, action) => {
  return state.setIn(["TVChannel", "data"], fromJS(action.payload));
};
const setTVChannelFailure: TChannelsType<TGetTVChannelError> = (state, action) => {
  return state.setIn(["TVChannel", "error"], fromJS(action.payload));
};

export default createReducer<TChannelsRecordOf>(initialState, {
  [GET_ALL_TV_CHANNELS_REQUEST]: setAllTVChannelsLoading(true),
  [GET_ALL_TV_CHANNELS_SUCCESS]: [setAllTVChannelsLoading(false), setAllTVChannelsSuccess],
  [GET_ALL_TV_CHANNELS_FAILURE]: [setAllTVChannelsLoading(false), setAllTVChannelsFailure],

  [GET_TV_CHANNEL_REQUEST]: setTVChannelLoading(true),
  [GET_TV_CHANNEL_SUCCESS]: [setTVChannelLoading(false), setTVChannelSuccess],
  [GET_TV_CHANNEL_FAILURE]: [setTVChannelLoading(false), setTVChannelFailure],
});
