import createAction from "../utils/createAction";
import {
  TGetAllTVChannelsError,
  TGetAllTVChannelsRequest,
  TGetAllTVChannelsSuccess,
  TGetTVChannelError,
  TGetTVChannelRequest,
  TGetTVChannelSuccess,
} from "./types";

export const GET_ALL_TV_CHANNELS_REQUEST = "GET_ALL_TV_CHANNELS_REQUEST";
export const GET_ALL_TV_CHANNELS_SUCCESS = "GET_ALL_TV_CHANNELS_SUCCESS";
export const GET_ALL_TV_CHANNELS_FAILURE = "GET_ALL_TV_CHANNELS_FAILURE";

export const GET_TV_CHANNEL_REQUEST = "GET_TV_CHANNEL_REQUEST";
export const GET_TV_CHANNEL_SUCCESS = "GET_TV_CHANNEL_SUCCESS";
export const GET_TV_CHANNEL_FAILURE = "GET_TV_CHANNEL_FAILURE";

export const getAllTVChannelsRequest = createAction<TGetAllTVChannelsRequest>(
  GET_ALL_TV_CHANNELS_REQUEST,
);
export const getAllTVChannelsSuccess = createAction<TGetAllTVChannelsSuccess>(
  GET_ALL_TV_CHANNELS_SUCCESS,
);
export const getAllTVChannelsFailure = createAction<TGetAllTVChannelsError>(
  GET_ALL_TV_CHANNELS_FAILURE,
);

export const getTVChannelRequest = createAction<TGetTVChannelRequest>(GET_TV_CHANNEL_REQUEST);
export const getTVChannelSuccess = createAction<TGetTVChannelSuccess>(GET_TV_CHANNEL_SUCCESS);
export const getTVChannelFailure = createAction<TGetTVChannelError>(GET_TV_CHANNEL_FAILURE);
