import React, { FC } from "react";

import { IDateProps } from "../../types";

import "./styles.scss";
import { TTVChannelData } from "../../../../store/channels/types";
import { useSelector } from "react-redux";
import {
  makeSelectTVChannelData,
  makeSelectTVChannelLoading,
} from "../../../../store/channels/selectors";
import { convertTVChannelDataToChannelData } from "../../../../utils/convertHelpers";
import moment from "moment";
import Preloader from "../../../Preloader";

const Date: FC<IDateProps> = ({ divRef }) => {
  // @ts-ignore
  const TVChannel: TTVChannelData = useSelector(makeSelectTVChannelData)?.toJS();
  const TVChannelLoading = useSelector(makeSelectTVChannelLoading);

  const channelData = convertTVChannelDataToChannelData(TVChannel);

  return (
    <div ref={divRef} className="date">
      <div className="date-list list-fon">
        <div className="date-list-body">
          <h2 className="date-list-text"> Дата </h2>
        </div>
        {TVChannelLoading ? (
          <Preloader />
        ) : (
          <div className="date-list-wrapper">
            <ul className="date-list-inner">
              {channelData?.programDaysList.map(({ timestamp }) => {
                return (
                  <li key={timestamp}>
                    <h4>{moment(timestamp).format("ddd")}</h4> <span>|</span>
                    <abbr>{moment(timestamp).format("D MMMM")}</abbr>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default Date;
