import { HelpIcon, MusicIcon, SettingIcon, TvIcon, UmbrellaIcon } from "../Icons";
import React from "react";

import { EMenuIds, TBaseItem, TMenuItem } from "./types";

export const mockMenuList: TMenuItem[] = [
  { id: EMenuIds.TVId, icon: <TvIcon />, title: "TV" },
  { id: EMenuIds.weatherId, icon: <UmbrellaIcon />, title: "Погода" },
  { id: EMenuIds.radioId, icon: <MusicIcon />, title: "Радио" },
  { id: EMenuIds.settingId, icon: <SettingIcon />, title: "Настройки" },
  { id: EMenuIds.complainId, icon: <HelpIcon />, title: "Жаловаться" },
];

export const mocBaseList: TBaseItem[] = [
  { title: "Основные" },
  { title: "Новостные" },
  { title: "Развлекательные" },
  { title: "Позновательные" },
  { title: "Спорт" },
  { title: "Сериал" },
  { title: "Детское" },
  { title: "Основные" },
  { title: "Основные" },
  { title: "Основные" },
  { title: "Основные" },
];

export const mockChannels = [
  {
    id: 1,
    isHeader: true,
    title: "Основные",
  },
  {
    id: 2,
    counter: 1,
    title: "Первый канал",
  },
  {
    id: 3,
    counter: 2,
    title: "Первый Россия",
  },
  {
    id: 4,
    counter: 3,
    title: "Первый Развлекательный",
  },
  {
    id: 5,
    isHeader: true,
    title: "Основные 1",
  },
  {
    id: 6,
    counter: 4,
    title: "Первый канал",
  },
  {
    id: 7,
    counter: 5,
    title: "Первый Россия",
  },
  {
    id: 8,
    counter: 6,
    title: "Первый Развлекательный",
  },
];
